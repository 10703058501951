import {useLazyQuery} from '@apollo/client';
import {Grid, TGridProps} from '@app/components/Common/Grid';
import DocumentModal from '@app/components/Modal/Documentation/document';
import {Document, DocumentView, GetAllDocumentViewsQuery} from '@app/graphql/__types__/graphql';
import {DOCUMENTATION_DOCUMENT_VIEWS_GET_ALL} from '@app/graphql/requests/documents';
import {useDataStore} from '@app/stores/data';
import {useDocumentStore} from '@app/stores/document';
import {GRID_DEFAULT_LAYOUT_DOC, OBJ_NEW_ID, ROUTE_DOCUMENTATION_DOC_DETAIL} from '@app/utils/constants';
import {RadButton} from '@holis/react-ui/rad';
import {useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {LuPlusCircle} from 'react-icons/lu';
import {MdOpenInNew} from 'react-icons/md';
import {useNavigate} from 'react-router-dom';

const GRID_NAME = 'documentation-assetGrid';

export default function DocumentationAssetPage() {
  const navigate = useNavigate();
  const {docs, setDocs, setActiveDoc, creatingDoc, setFetchDocs, resetData} = useDocumentStore();
  const {t} = useTranslation();
  const {selectedSites} = useDataStore();
  // const [getDocsApi, {loading, error, refetch, called}] = useLazyQuery<GetAllDocumentsQuery>(DOCUMENTATION_DOCUMENTS_GET_ALL, {
  //   variables: {
  //     orderBy: [
  //       {
  //         id: 'desc',
  //       },
  //     ],
  //   },
  //   fetchPolicy: 'no-cache',
  // });

  const [getDocsApi, {loading, error}] = useLazyQuery<GetAllDocumentViewsQuery>(DOCUMENTATION_DOCUMENT_VIEWS_GET_ALL);

  // const handleCloseModal = (e?: BeforeUnloadEvent, setExitNoSaveModalShown?: (open: boolean) => void) => {
  //   if (!isSaved()) {
  //     if (e) {
  //       e.preventDefault();
  //       e.returnValue = t('message.warning.changesNotSavedUnloadWindow');
  //       return t('message.warning.changesNotSavedUnloadWindow');
  //     }

  //     setExitNoSaveModalShown?.(true);
  //   } else {
  //     resetData();
  //   }
  // };

  // const confirmExitWithoutSaving = () => {
  //   resetData();
  // };

  const getDocs = () => {
    if (selectedSites?.length) {
      getDocsApi({
        variables: {
          sites: selectedSites,
          orderBy: [
            {
              id: 'desc',
            },
          ],
        },
        fetchPolicy: 'no-cache',
      }).then(queryResult => setDocs((queryResult.data?.documentViews ?? []) as Document[]));
    } else {
      setDocs([]);
    }
  };

  const handleNewDoc = () => {
    setActiveDoc({
      id: OBJ_NEW_ID,
    }, true);
  };

  useEffect(() => {
    getDocs();
    setFetchDocs(getDocs);
    return () => {
      resetData();
    };
  }, [selectedSites]);

  useEffect(() => {
    if (!localStorage.getItem(`TREELIST_${GRID_NAME}`)) {
      localStorage.setItem(`TREELIST_${GRID_NAME}`, JSON.stringify(GRID_DEFAULT_LAYOUT_DOC));
    }
  }, []);

  const gridProps: TGridProps = useMemo(() => ({
    columns: [
      {
        field: 'action',
        title: t('label.action'),
        type: 'string',
        width: 91,
        cellRenderer: 'action',
        titleHidden: true,
        filter: false,
      },
      {
        field: 'id',
        title: 'Id',
        type: 'number',
        width: 80,
        hidden: true,
      },
      {
        field: 'sector_site_site',
        title: t('label.site'),
        type: 'string',
        width: 114,
      },
      {
        field: 'sector_sector',
        title: t('label.sector'),
        type: 'string',
        width: 97,
      },
      {
        field: 'document',
        title: t('label.document'),
        type: 'string',
        width: 261,
        cellRenderer: 'document',
      },
      {
        field: 'description',
        title: t('label.description'),
        type: 'string',
        width: 975,
        ignoreCase: true,
      },
      {
        field: 'type_code',
        title: t('label.type'),
        type: 'string',
        width: 103,
      },
      {
        field: 'discipline_code',
        title: t('label.discipline'),
        type: 'string',
        width: 108,
      },
      {
        field: 'catalog_code',
        title: t('label.catalog'),
        type: 'string',
        width: 122,
        hidden: true,
      },
      {
        field: 'phase_code',
        title: t('label.phase'),
        type: 'string',
        width: 117,
      },
      {
        field: 'stage_code',
        title: t('label.stage'),
        type: 'string',
        width: 110,
      },
      {
        field: 'docName',
        title: t('label.docName'),
        type: 'string',
        width: 279,
      },
      {
        field: 'revision',
        title: t('label.revision'),
        type: 'string',
        width: 155,
      },
      {
        field: 'originator_code',
        title: t('label.originator'),
        type: 'string',
        width: 80,
        hidden: true,
      },
      {
        field: 'flagStatus',
        title: t('label.status'),
        type: 'string',
        width: 80,
        cellRenderer: 'flagStatus',
        hidden: true,
      },
    ],
    cellRenderers: {
      action: (_val: string, rowData: Partial<DocumentView>) => (
        <RadButton
          size='icon'
          variant='outline'
          className='size-fit p-1'
          onClick={() => navigate(ROUTE_DOCUMENTATION_DOC_DETAIL.replace(':number', `${rowData.document}`))}
        >
          <MdOpenInNew size={18}/>
        </RadButton>
      ),
      document: (val: string) => <span className='font-bold'>{val}</span>,
      flagStatus: (val: string) => val ? t(`label.flagStatus.${val}`) : '',
    },
  }), [docs]);

  const columnsAlwaysVisible: string[] = useMemo(() => ([
    'action',
    'document',
    'description',
    'revision',
  ]), [docs]);

  return (
    <div className='h-full'>
      {creatingDoc && (
        <DocumentModal
          isOpen={creatingDoc}
          onOpenChange={isOpen => {
            if (!isOpen) {
              setActiveDoc(undefined);
            }
          }}
        />
      )}

      <Grid
        gridName={GRID_NAME}
        queryData={{
          data: docs ?? [],
          loading,
          error,
        }}
        gridProps={gridProps}
        title={<div className='text-lg font-semibold'>{t('label.asset')}</div>}
        columnsAlwaysVisible={columnsAlwaysVisible}
      >
        <RadButton
          size='sm'
          className='flex gap-1 text-sm'
          onClick={handleNewDoc}
        >
          <LuPlusCircle/> {t('label.addDocument')}
        </RadButton>
      </Grid>

      {/* {!!activeDoc && <DocumentationDocumentModal confirmOnExitNoSave open item={activeDoc} onConfirmExitWithoutSaving={confirmExitWithoutSaving} onClose={handleCloseModal}/>} */}
    </div>
  );
}
