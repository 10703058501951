import React, {useState} from 'react';
import {SchedulingBadge} from '..';
import EventListPage from '@app/components/Common/Block/Event/EventListPage';
import {FORMAT_DATE_EU, ROUTE_SCHEDULING_INSPECTION_HISTORY_DETAIL, ROUTE_SCHEDULING_INSPECTION_HISTORY} from '@app/utils/constants';
import {EDownloadFileType, EWORKORDER_EVENT_STAGE} from '@app/utils/enums';
import {useTranslation} from 'react-i18next';
import {EventView} from '@app/graphql/__types__/graphql';
import {RadButton, RadBadge} from '@holis/react-ui/rad';
import moment from 'moment';
import {MdOpenInNew} from 'react-icons/md';
import {useLayoutStore} from '@app/stores/layout';
import AppNotifications from '@app/services/notification';
import {OptimusClientConfig} from '@app/utils/clientConfig';
import {addTokenToUrl, downloadFileFromUrl, generateAbsoluteUrlWithBaseUrl, tailwindColorToBgFgStyle} from '@app/utils/functions';
import {getDocNameTypeLabel, getFileHeader, getGenerationUrlTypeLabel, ItemType} from '@app/utils/hooks/useReportGeneration';
import {useHolisAuth} from '@holis/auth-client-react';
import {TGridProps} from '@app/components/Common/Grid';
import UsersToSendReportModal from './components/UsersToSendReportModal';
import axios from 'axios';
import {TObjId} from '@app/types/app';
import {generatePath, useNavigate} from 'react-router-dom';

export default function InspectionHistory() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [eventToPublish, setEventToPublish] = useState<EventView>();
  const {startLoading, stopLoading} = useLayoutStore();
  const {getAccessToken} = useHolisAuth();
  const downloadReport = async (row: EventView) => {
    startLoading();
    const reportUrl = await addTokenToUrl(`${OptimusClientConfig.current.fileBaseUrl}/${getDocNameTypeLabel(ItemType.event)}-${row.event}?type=${EDownloadFileType.REPORT}`, getAccessToken);

    downloadFileFromUrl(reportUrl, `${getFileHeader(ItemType.event)}${row.reportNumber}.pdf`, {
      onEnd() {
        stopLoading();
      },
      onFail(err) {
        console.log(err);
        stopLoading();
        AppNotifications.error(t('message.error.default.title'));
      },
      getAccessToken,
    });
  };

  const handleSendReport = async (selectedItems: TObjId[]) => {
    const token = await getAccessToken();
    const sendReportUrl = `${OptimusClientConfig.current.reportBaseUrl}/${getGenerationUrlTypeLabel(ItemType.event)}/${eventToPublish!.id}/send`;
    // const reportUrl = await addTokenToUrl(`${OptimusClientConfig.current.fileBaseUrl}/${`${getDocNameTypeLabel(ItemType.event)}-${eventToPublish?.event}`}?type=${EDownloadFileType.REPORT}`, getAccessToken);
    const reportUrl = generateAbsoluteUrlWithBaseUrl(generatePath(ROUTE_SCHEDULING_INSPECTION_HISTORY_DETAIL, {number: eventToPublish!.event!}));
    console.log(reportUrl);
    startLoading();
    axios.post(sendReportUrl, {
      userIds: selectedItems.map(objId => objId.id as string),
      link: reportUrl,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(_res => {
      AppNotifications.success(t('message.success.report.sent'));
      setEventToPublish(undefined);
      navigate(0);
    }).catch(() => {
      AppNotifications.error(t('message.error.default.title'));
    }).finally(stopLoading);
  };

  const gridProps: TGridProps = {
    columns: [
      {
        field: 'report',
        title: t('label.report'),
        type: 'string',
        width: 77.5,
        cellRenderer: 'action',
        filter: false,
      },
      {
        field: 'isPublished',
        title: t('label.publish'),
        type: 'boolean',
        width: 120,
        cellRenderer: 'isPublished',
        filter: false,
      },
      {
        field: 'id',
        title: 'Id',
        type: 'number',
        width: 80,
        hidden: true,
      },
      {
        field: 'event',
        title: t('label.event'),
        type: 'string',
        width: 129,
        cellRenderer: 'textBold',
        hidden: false,
      },
      {
        field: 'description',
        title: t('label.description'),
        type: 'string',
        width: 289,
        hidden: false,
      },
      {
        field: 'schedulingTag_floc',
        title: t('label.schedulingTag'),
        type: 'string',
        width: 294,
        hidden: false,
      },
      {
        field: 'schedulingTag_description',
        title: t('label.schedulingTagDescription'),
        type: 'string',
        width: 445,
      },
      {
        field: 'schedulingTag_techClass_techClass',
        title: t('label.technicalClass'),
        type: 'string',
        width: 120,
        hidden: false,
      },
      {
        field: 'schedulingTag_techClass_class_class',
        title: t('label.class'),
        type: 'string',
        width: 77.5,
      },
      {
        field: 'schedulingTag_classSece',
        title: t('label.classSece'),
        type: 'string',
        width: 100,
        hidden: true,
      },
      {
        field: 'schedulingTag_sector_site_site',
        title: t('label.site'),
        type: 'string',
        width: 77.5,
      },
      {
        field: 'schedulingTag_sector_sector',
        title: t('label.sector'),
        type: 'string',
        width: 77.5,
      },
      {
        field: 'schedulingTag_grid_grid',
        title: t('label.grid'),
        type: 'string',
        width: 77.5,
        hidden: true,
      },
      {
        field: 'notes',
        title: t('label.notes'),
        type: 'string',
        width: 120,
        hidden: true,
      },
      {
        field: 'type_type',
        title: t('label.type'),
        type: 'string',
        width: 77.5,
        hidden: false,
      },
      {
        field: 'inspectionDate',
        title: t('label.inspectionDate'),
        type: 'string',
        cellRenderer: 'date',
        width: 137,
      },
      {
        field: 'inspectorId',
        title: t('label.inspectorId'),
        type: 'string',
        width: 80,
        hidden: true,
      },
      {
        field: 'reviewerId',
        title: t('label.reviewerId'),
        type: 'string',
        width: 80,
        hidden: true,
      },
      {
        field: 'approverId',
        title: t('label.approverId'),
        type: 'string',
        width: 80,
        hidden: true,
      },
      {
        field: 'reportNumber',
        title: t('label.reportNumber'),
        type: 'string',
        width: 80,
        hidden: true,
      },
      {
        field: 'reportDescription',
        title: t('label.reportDescription'),
        type: 'string',
        width: 80,
        hidden: true,
      },
      {
        field: 'reportingDate',
        title: t('label.reportingDate'),
        type: 'string',
        width: 80,
        cellRenderer: 'date',
        hidden: true,
      },
      {
        field: 'mainWorkCenter_code',
        title: t('label.mainWorkCenter'),
        type: 'string',
        width: 77.5,
      },
      {
        field: 'plannerGroup_code',
        title: t('label.plannerGroup'),
        type: 'string',
        width: 77.5,
      },
      {
        field: 'externalId',
        title: t('label.externalId'),
        type: 'string',
        width: 77.5,
      },
      {
        field: 'status',
        title: t('label.status'),
        type: 'string',
        width: 117,
        cellRenderer: 'status',
        hidden: false,
        pinned: false,
      },
      {
        field: 'exportStatus',
        title: t('label.exportStatus'),
        type: 'string',
        width: 117,
        hidden: false,
        pinned: false,
      },
    ],
    cellRenderers: {
      action(_val: string, rowData: unknown) {
        const viewDatas = rowData as EventView;
        return (
          <div className='!flex gap-4 items-center'>
            <RadButton
              size='icon'
              variant='outline'
              className='size-fit p-1'
              onClick={() => downloadReport(viewDatas)}
            >
              <MdOpenInNew size={18}/>
            </RadButton>
          </div>
        );
      },
      isPublished(val: string, rowData: unknown) {
        return (rowData as EventView).isPublished ? <RadBadge variant='outline' className='bg-green-50 text-green-700 hover:bg-green-50 hover:text-green-700'>{t('label.published')}</RadBadge> : <RadButton size='sm' className='size-fit p-1' onClick={() => setEventToPublish(rowData as EventView)}>{t('label.publish')}</RadButton>;
      },
      status(val: string, rowData: unknown) {
        if (!val) {
          return '';
        }

        const viewDatas = rowData as EventView;

        return (
          <RadBadge variant='outline' className='py-1 font-normal' style={tailwindColorToBgFgStyle(viewDatas.status_displayColor)}>
            {viewDatas.status_description}
          </RadBadge>
        );
      },
      date: (val: string) => val ? moment(val).format(FORMAT_DATE_EU) : '',
      textBold: (val: string) => val ? <span className='font-bold'>{val}</span> : '',
    },
    canSelect: false,
    onRowsSelection: undefined,
  };

  return (
    <>
      <EventListPage hasYearClassFilter title='label.menu.inspectionHistory' stage={EWORKORDER_EVENT_STAGE.COMPLETED} badge={<SchedulingBadge/>} route={ROUTE_SCHEDULING_INSPECTION_HISTORY} gridProps={gridProps} columnsAlwaysVisible={['event', 'description', 'schedulingTag_floc', 'schedulingTag_techClass_techClass', 'type_type', 'status', 'exportStatus']}/>
      {!!eventToPublish && <UsersToSendReportModal open event={eventToPublish} onValidate={handleSendReport} onClose={() => setEventToPublish(undefined)}/>}
    </>
  );
}
