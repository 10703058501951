import {useLazyQuery} from '@apollo/client';
import SingleContentModal from '@app/components/Modal/SingleFormModal';
import {GetNotificationExportStatusQuery, GetWorkorderExportStatusQuery} from '@app/graphql/__types__/graphql';
import {NOTIFICATION_GET_EXPORT_STATUS, WORKORDER_GET_EXPORT_STATUS} from '@app/graphql/requests';
import {ExportStatus, ExportStep} from '@app/types/export-datas-types';
import moment from 'moment';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LuCheckCircle, LuXCircle, LuCircle, LuArrowRight} from 'react-icons/lu';

export type TExportErrorsModal = Readonly<{
	opened?: boolean;
	onClose?: () => void;
  title?: string;
  itemType: 'ian' | 'wrko';
  itemId: number;
}>;
export default function ExportStatusModal({opened, onClose, title, itemType, itemId}: TExportErrorsModal) {
  const {t} = useTranslation();
  const [exportStatus, setExportStatus] = useState<ExportStatus>();
  const [getNotificationExportStatusApi] = useLazyQuery<GetNotificationExportStatusQuery>(NOTIFICATION_GET_EXPORT_STATUS, {
    fetchPolicy: 'no-cache',
  });
  const [getWrkoExportStatusApi] = useLazyQuery<GetWorkorderExportStatusQuery>(WORKORDER_GET_EXPORT_STATUS, {
    fetchPolicy: 'no-cache',
  });

  const getStepStatusIcon = (status: string) => {
    switch (status) {
      case 'pending':
        return <LuCircle/>;
      case 'success':
        return <LuCheckCircle/>;
      case 'error':
        return <LuXCircle/>;
      default:
        return null;
    }
  };

  const getStepStatusClassName = (status: string) => {
    switch (status) {
      case 'pending':
        return 'text-black-50';
      case 'success':
        return 'text-green-600';
      case 'error':
        return 'text-red-500';
      default:
        return '';
    }
  };

  const setExportStatusFromJson = (json: unknown) => {
    if (!json) {
      setExportStatus({status: 'pending', steps: []});
    }

    const steps = json as ExportStep[];
    const hasError = steps?.some(step => step.status?.status === 'error');
    const isSuccess = steps?.every(step => step.status?.status === 'success');
    const hasProcessing = steps?.some(step => step.status?.status === 'processing');
    const st = {status: isSuccess ? 'success' : (hasError ? 'error' : (hasProcessing ? 'processing' : 'pending')), steps};
    setExportStatus(st as ExportStatus);
  };

  useEffect(() => {
    if (opened) {
      if (itemType === 'ian') {
        getNotificationExportStatusApi({variables: {id: itemId}}).then(
          ({data}) => {
            setExportStatusFromJson(data?.notification?.statusExport);
          },
        );
      } else {
        getWrkoExportStatusApi({variables: {id: itemId}}).then(
          ({data}) => {
            setExportStatusFromJson(data?.event?.statusExport);
          },
        );
      }
    }
  }, [opened, itemType, itemId, getNotificationExportStatusApi, getWrkoExportStatusApi]);

  return exportStatus ? (

    <SingleContentModal
      isOpen={opened}
      containerClassName='max-w-[800px] min-w-0'
      title={title ?? t('label.exportStatus')}
      onOpenChange={isOpen => {
        if (!isOpen) {
          onClose?.();
        }
      }}>
      <div className='text-base uppercase'>
        {t('label.exportStatus')}: <span className={getStepStatusClassName(exportStatus.status)}>{exportStatus.status}</span>
      </div>
      <div className='text-sm mt-4'>
        {exportStatus.steps?.map(step => (
          <div key={step.name} className={getStepStatusClassName(step.status?.status ?? 'pending') + ' mt-4 text-gray-500'}>
            <div className='flex gap-2 items-center'>{getStepStatusIcon(step.status?.status ?? 'pending')} <b>{step.name}</b>: {step.status?.status ?? 'pending'}</div>
            {step.status?.message && <div className='flex gap-2 items-center mt-1 ml-2'>
              <div className='flex gap-2 items-center'>
                <div className='text-xs'>{moment(step.status.timestamp ?? 0).format('Y-MM-DD HH:mm:ss')}</div>
                <LuArrowRight/>
              </div>
              <pre className='flex-1 text-xs w-full overflow-x-auto max-h-[150px]'>{step.status.message}</pre>
            </div>}
          </div>
        ))}
      </div>
    </SingleContentModal>
  ) : null;
}
